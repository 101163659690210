/* eslint-disable camelcase */
import React from "react";
import { H1, P2 } from "app/components/Typography";
import Flex from "app/components/Flex";

const Headline = props => {
  const { header, subheader } = props;

  return (
    <Flex flexDirection="column" justifyContent="space-between">
      <H1 color="white">{header}</H1>
      {subheader && (
        <P2 color="white" mt={{ _: 0, lg: 10 }}>
          {subheader}
        </P2>
      )}
    </Flex>
  );
};

export default Headline;
